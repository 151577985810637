<template>
  <div v-if="user" :class="[{ 'bg-white': !isNotOpenInModal }]">
    <div class="pb-6 text-xs md:text-base">
      <div
        :class="[
          { 'bg-primary-white p-3 border border-primary-gray-200 rounded-lg': isNotOpenInModal },
        ]"
      >
        <div class="flex gap-3 flex-col md:flex-row">
          <div class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span v-i18n="title">{{ additionalInfo.name || 'Name' }}</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light capitalize md:w-3/4">
              {{ user?.user_details?.full_name || defaultValue }}
            </div>
          </div>
          <div class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span v-i18n="title">Gender</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light capitalize md:w-3/4">
              {{ user?.user_details?.gender || defaultValue }}
            </div>
          </div>
        </div>
        <div class="flex gap-3 mt-3 flex-col md:flex-row flex-wrap lg:mt-5">
          <div class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span v-i18n="title">DOB</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light md:w-3/4">
              {{
                user?.user_details?.profile && user?.user_details?.profile.date_of_birth
                  ? user?.user_details?.profile.date_of_birth
                  : defaultValue
              }}
            </div>
          </div>
          <div class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span v-i18n="title">Phone Number</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light md:w-3/4">
              {{ user?.user_details?.phone || defaultValue }}
            </div>
          </div>
        </div>
        <div class="flex gap-3 flex-col md:flex-row flex-wrap mt-3 lg:mt-5">
          <div v-if="user.username" class="flex-1 flex">
            <div class="font-bold min-w-1 md:w-2/4">
              <span v-i18n="title">User Name</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light md:w-3/4">
              {{ user.username || defaultValue }}
            </div>
          </div>
          <div class="flex flex-1">
            <div class="font-bold" :class="emailClass">
              <span v-i18n="title">Email</span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light md:w-3/4 -ml-1 break-all">
              {{ user?.user_details?.email || defaultValue }}
            </div>
          </div>
          <div v-if="additionalInfo.additionalTitle" class="flex flex-1">
            <div class="font-bold min-w-1 md:w-2/4">
              <span v-i18n="title">
                {{ additionalInfo.additionalTitle }}
              </span>
              <span>:</span>
            </div>
            <div class="text-primary-grey-light md:w-3/4 break-all">
              {{ additionalInfo.additionalValue }}
            </div>
          </div>
          <div v-if="user.requestStatus" class="flex flex-1">
            <div class="font-bold align-middle min-w-1 md:w-2/4">
              <span>Request Status</span>
              <span>:</span>
            </div>
            <div class="capitalize md:w-3/4 text-xs">
              <span>
                <div
                  :style="pickupRequestStyle(user.requestStatus)"
                  class="font-roboto font-normal text-text-color text-sm text-center capitalize md:w-30"
                >
                  {{ user?.requestStatus || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <template v-if="isLoading">
        <Loader class="mt-20 mb-40" :content="true" />
      </template>
      <div v-for="(entity, ind) in detailedList" :key="ind" class="flex flex-col gap-2 mt-5">
        <div class="flex flex-row flex-wrap">
          <div class="flex flex-col gap-2">
            <div class="font-extrabold md:text-lg text-base">
              <span>{{ $t(`title.${entity.title}`) }}</span>
              <span>:</span>
            </div>
            <div v-if="entity.description" class="text-primary-grey-light">
              <span>{{ entity.description }}</span>
            </div>
          </div>
        </div>

        <div
          :class="[{ 'bg-primary-white': isNotOpenInModal }]"
          class="overflow-x-auto xl:max-w-screen-xl lg:max-w-screen-md md:max-w-screen-sm sm:max-w-xl custom-max-w rounded-lg mx-0 border border-primary-grey"
        >
          <table class="w-full border-primary-grey table-auto whitespace-nowrap">
            <thead class="bg-bright-gray">
              <tr class="text-center">
                <th
                  v-for="head in entity.tableHead"
                  :key="head"
                  class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pr-4 rtl:pl-0 ltr:pl-4 ltr:pr-0"
                >
                  <span
                    v-i18n="dashboard"
                    class="rtl:border-l ltr:border-r border-primary-grey w-full block rtl:pl-3 ltr:pr-3"
                  >
                    {{ head }}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(record, index) in entity.records"
                :key="index"
                class="border-t border-primary-grey text-center"
              >
                <td
                  v-for="(item, id) in record"
                  :key="id"
                  class="rtl:border-l ltr:border-r border-primary-grey rtl:text-right pl-12 pr-2.5 py-5"
                >
                  <span
                    v-if="
                      [userRoles.GUARDIAN_OF, userRoles.PICKUP_PERSON_OF].includes(entity.title) &&
                      entity.chipFlag.includes(id)
                    "
                    class="font-roboto font-normal text-text-color text-sm"
                  >
                    <MultiUserDisplay
                      :options="item"
                      :dropdown-top-bottom="
                        dropdownTopBottom(index, entity.records, 'bottom-6', 'top-6')
                      "
                      :tooltip="
                        dropdownTopBottom(index, entity.records, 'tooltip-bottom', 'tooltip-top')
                      "
                    />
                  </span>
                  <span v-else class="font-roboto font-normal text-text-color text-sm">
                    {{ item || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          v-if="entity.pagination"
          :record-limit="filteredRecordLimit"
          :max-range="paginationCounts(entity.recordsCount, filteredRecordLimit)"
          @filterRecord="entity.getRecords"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@components/BaseComponent/Pagination.vue'
import userRoles from '@src/constants/user-roles-constants.js'
import { mapState } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import { dropdownTopBottom } from '@src/utils/generalUtil.js'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import { pickupRequestStyle } from '@src/utils/settings/tenant-user.util.js'
import generalUtil from '@src/mixins/general-mixins.js'
import { mapActions } from 'vuex'
import MultiUserDisplay from '@src/components/UiElements/MultiUserDisplay.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
export default {
  components: {
    Pagination,
    Loader,
    MultiUserDisplay,
  },
  mixins: [generalUtil],
  props: {
    user: {
      type: Object,
      default() {
        return {}
      },
    },
    additionalInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    isNotOpenInModal: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: String,
      default: GENERAL_CONSTANTS.NOT_APPLICABLE,
    },
  },
  data() {
    return {
      GENERAL_CONSTANTS,
      title: 'title',
      filteredRecordLimit: 5,
      detailedList: [],
      isLoading: false,
      userRoles,
      dashboard: 'dashboard',
    }
  },
  computed: {
    ...mapState({
      instituteName: (state) => state.settings.generalSettingInstituteName,
    }),
    emailClass() {
      return this.user.username || this.additionalInfo.additionalTitle
        ? 'min-w-1 md:w-2/4 mr-1'
        : 'min-w-1 md:w-1/5 mr-1 md:mr-0 '
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(value) {
        this.detailedList = []
        this.roleDetail()
      },
    },
  },
  methods: {
    pickupRequestStyle,
    dropdownTopBottom,
    getGuardiansRecords(range) {
      let query = {
        id: this.user?.id,
        payload: paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT),
      }
      this.isLoading = true
      this.getGuardianDetails(query)
        .then((res) => {
          this.setUserData(userRoles.GUARDIAN_OF, res.data?.records)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getStudentsRecords(range) {
      let query = {
        id: this.user?.id,
        payload: paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT),
      }
      this.isLoading = true
      this.getSectionStudentDetails(query)
        .then((res) => {
          this.setUserData(userRoles.STUDENT, res.data?.records)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getSectionAdminRecords(range) {
      let query = {
        id: this.user?.id,
        payload: paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT),
      }
      this.isLoading = true
      this.getSectionAdminDetails(query)
        .then((res) => {
          this.setUserData(userRoles.SECTION_ADMIN, res.data?.records)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getPickPersonRecords(range) {
      let query = {
        id: this.user?.id,
        payload: paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT),
      }
      this.isLoading = true
      this.getPickupPersonDetails(query)
        .then((res) => {
          this.setUserData(userRoles.PICKUP_PERSON_OF, res.data?.records)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getStaffRecords(range) {
      let query = {
        id: this.user?.id,
        payload: paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT),
      }
      this.isLoading = true
      this.getStaffDetails(query)
        .then((res) => {
          this.setUserData(userRoles.STAFF, res.data?.records)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getTeacherRecords(range) {
      let query = {
        id: this.user?.id,
        payload: paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT),
      }
      this.isLoading = true
      this.getSectionTeacherDetails(query)
        .then((res) => {
          this.setUserData(userRoles.TEACHER, res.data?.records)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getCampusAdminRecords(range) {
      let query = {
        id: this.user?.id,
        payload: paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT),
      }
      this.isLoading = true
      this.getCampusAdminDetails(query)
        .then((res) => {
          this.setUserData(userRoles.CAMPUS_ADMIN, res.data?.records)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    setUserData(user, records) {
      let mutatedRecords = []
      switch (user) {
        case userRoles.GUARDIAN_OF:
          mutatedRecords = this.transformGuardianRecords(records)
          break
        case userRoles.CAMPUS_ADMIN:
          mutatedRecords = this.transformCampusAdminRecords(records)
          break
        case userRoles.TEACHER:
          mutatedRecords = this.transformTeacherRecords(records)
          break
        case userRoles.STAFF:
          mutatedRecords = this.transformStaffRecords(records)
          break
        case userRoles.PICKUP_PERSON_OF:
          mutatedRecords = this.transformPickupPersonRecords(records)
          break
        case userRoles.SECTION_ADMIN:
          mutatedRecords = this.transformSectionAdminRecords(records)
          break
        case userRoles.STUDENT:
          mutatedRecords = this.transformStudentRecords(records)
          break
        default:
          mutatedRecords = []
          break
      }
      this.detailedList.forEach((listItem) => {
        if (listItem.title === user) listItem.records = mutatedRecords
      })
    },
    transformGuardianRecords(records) {
      let guardianDetails = records.map((guardian) => {
        return {
          student: guardian?.first_name + ' ' + guardian?.last_name,
          campus: guardian?.users_roles_entities?.map((role) => {
            return role?.campus?.title || ''
          }),
          class: guardian?.users_roles_entities?.map((role) => {
            return role?.class?.title || ''
          }),
          section: guardian?.users_roles_entities?.map((role) => {
            return role?.section?.title || ''
          }),
        }
      })
      return guardianDetails
    },
    transformCampusAdminRecords(records) {
      let campusDetails = records.map((val) => {
        return {
          campus: val?.campus?.title,
        }
      })
      return campusDetails
    },
    transformTeacherRecords(records) {
      let teacherDetails = records.map((teacher) => {
        return {
          campus: teacher?.campus?.title,
          class: teacher?.class?.title,
          section: teacher?.section?.title,
        }
      })
      return teacherDetails
    },
    transformStaffRecords(records) {
      let staffDetails = records.map((staff) => {
        return { campus: staff?.campus?.title }
      })
      return staffDetails
    },
    transformPickupPersonRecords(records) {
      let pickupPersonDetails = records.map((pickupPerson) => {
        return {
          student: pickupPerson?.first_name + ' ' + pickupPerson?.last_name,
          campus: pickupPerson?.users_roles_entities?.map((role) => {
            return role?.campus?.title || ''
          }),
          class: pickupPerson?.users_roles_entities?.map((role) => {
            return role?.class?.title || ''
          }),
          section: pickupPerson?.users_roles_entities?.map((role) => {
            return role?.section?.title || ''
          }),
        }
      })
      return pickupPersonDetails
    },
    transformSectionAdminRecords(records) {
      let sectionAdminDetails = records.map((sectionAdmin) => {
        return {
          campus: sectionAdmin?.campus?.title,
          class: sectionAdmin?.class?.title,
          section: sectionAdmin?.section?.title,
        }
      })
      return sectionAdminDetails
    },
    transformStudentRecords(records) {
      let studentDetails = records.map((student) => {
        return {
          campus: student?.campus?.title,
          class: student?.class?.title,
          section: student?.section?.title,
        }
      })
      return studentDetails
    },
    setOwnerData() {
      this.detailedList.push({
        title: userRoles.OWNER,
        description: `User is the owner of Institute "${this.instituteName}"`,
      })
    },
    setSuperAdminData() {
      this.detailedList.push({
        title: userRoles.SUPER_ADMIN,
        description: `User is the Super Admin of Institute "${this.instituteName}"`,
      })
    },
    setCampusAdminData({ records, meta }) {
      this.detailedList.push({
        title: userRoles.CAMPUS_ADMIN,
        tableHead: ['Campus'],
        records: this.transformCampusAdminRecords(records),
        recordsCount: meta?.total_records,
        pagination: meta?.total_records > this.filteredRecordLimit,
        getRecords: (range) => {
          this.getCampusAdminRecords(range)
        },
      })
    },
    setGuardianData({ records, meta }) {
      this.detailedList.push({
        title: userRoles.GUARDIAN_OF,
        tableHead: ['Student Name', 'Campus', 'Class', 'Section'],
        records: this.transformGuardianRecords(records),
        chipFlag: ['campus', 'class', 'section'],
        recordsCount: meta?.total_records,
        pagination: meta?.total_records > this.filteredRecordLimit,
        getRecords: (range) => {
          this.getGuardiansRecords(range)
        },
      })
    },
    setTeacherData({ records, meta }) {
      this.detailedList.push({
        title: userRoles.TEACHER,
        tableHead: ['Campus', 'Class', 'Section'],
        records: this.transformTeacherRecords(records),
        recordsCount: meta?.total_records,
        pagination: meta?.total_records > this.filteredRecordLimit,
        getRecords: (range) => {
          this.getTeacherRecords(range)
        },
      })
    },
    setStaffData({ records, meta }) {
      this.detailedList.push({
        title: userRoles.STAFF,
        tableHead: ['Campus'],
        records: this.transformStaffRecords(records),
        recordsCount: meta?.total_records,
        pagination: meta?.total_records > this.filteredRecordLimit,
        getRecords: (range) => {
          this.getStaffRecords(range)
        },
      })
    },
    setPickupPersonData({ records, meta }) {
      this.detailedList.push({
        title: userRoles.PICKUP_PERSON_OF,
        tableHead: ['Student Name', 'Campus', 'Class', 'Section'],
        records: this.transformPickupPersonRecords(records),
        recordsCount: meta?.total_records,
        pagination: meta?.total_records > this.filteredRecordLimit,
        chipFlag: ['campus', 'class', 'section'],
        getRecords: (range) => {
          this.getPickPersonRecords(range)
        },
      })
    },
    setSectionAdminData({ records, meta }) {
      this.detailedList.push({
        title: userRoles.SECTION_ADMIN,
        tableHead: ['Campus', 'Class', 'Section'],
        records: this.transformSectionAdminRecords(records),
        recordsCount: meta?.total_records,
        pagination: meta?.total_records > this.filteredRecordLimit,
        getRecords: (range) => {
          this.getSectionAdminRecords(range)
        },
      })
    },
    setStudentData({ records, meta }) {
      this.detailedList.push({
        title: userRoles.STUDENT,
        tableHead: ['Campus', 'Class', 'Section'],
        records: this.transformStudentRecords(records),
        recordsCount: meta?.total_records,
        pagination: meta?.total_records > this.filteredRecordLimit,
        getRecords: (range) => {
          this.getStudentsRecords(range)
        },
      })
    },
    roleDetail() {
      let isOwner = this.user?.is_owner
      let isSuperAdmin = this.user?.is_super_admin
      let campusAdminRecords = this.user?.campus_admin_details?.records?.length
      let guardianRecords = this.user?.guardian_details?.records?.length
      let staffRecords = this.user?.staff_details?.records?.length
      let teacherRecords = this.user?.section_teacher_details?.records?.length
      let pickupPersonRecords = this.user?.pickup_person_details?.records?.length
      let sectionAdminRecords = this.user?.section_admin_details?.records?.length
      let studentRecords = this.user?.section_student_details?.records?.length

      if (isOwner) this.setOwnerData()
      if (isSuperAdmin) this.setSuperAdminData()
      if (campusAdminRecords) this.setCampusAdminData(this.user?.campus_admin_details)
      if (guardianRecords) this.setGuardianData(this.user?.guardian_details)
      if (staffRecords) this.setStaffData(this.user?.staff_details)
      if (teacherRecords) this.setTeacherData(this.user?.section_teacher_details)
      if (pickupPersonRecords) this.setPickupPersonData(this.user?.pickup_person_details)
      if (sectionAdminRecords) this.setSectionAdminData(this.user?.section_admin_details)
      if (studentRecords) this.setStudentData(this.user?.section_student_details)
    },
    ...mapActions('staff', [
      'getPickupPersonDetails',
      'getGuardianDetails',
      'getSectionStudentDetails',
      'getSectionTeacherDetails',
      'getSectionAdminDetails',
      'getCampusAdminDetails',
      'getStaffDetails',
    ]),
  },
}
</script>

<style lang="scss" module>
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>

<style lang="scss" scoped>
table tr td:last-child,
th:last-child > span {
  border: none;
}
@media (min-width: 500px) and(max-width: 639px) {
  .custom-max-w {
    width: 360px;
  }
}
@media (min-width: 420px) and (max-width: 499px) {
  .custom-max-w {
    width: 347px;
  }
}
@media (min-width: 370px) and (max-width: 419px) {
  .custom-max-w {
    width: 327px;
  }
}
@media (min-width: 341px) and(max-width: 369px) {
  .custom-max-w {
    width: 270px;
  }
}
@media (max-width: 340px) {
  .custom-max-w {
    width: 250px;
  }
}
</style>
